import React from 'react'
import Layout from '../../templates/layout'
import Seo from '../../helpers/seo'
import Slogan from '../../components/molecules/Slogan'
import SectionContainer from '../../components/molecules/SectionContainer'
import './styles.scss'

const Privacy = () => {
  return (
    <SectionContainer>
      <article className="privacy">
        <p className="privacy__paragraph">
          Seja bem-vindo ao website da <b>DIGITAL REPUBLIC</b>{' '}
          (“<a href="https://www.digitalrepublic.com.br/" className="privacy__paragraph--link">https://www.digitalrepublic.com.br/</a>”). Este Site é controlado e operado pela{' '}
          <b>DIGITAL REPUBLIC SERVIÇOS DE TECNOLOGIA LTDA</b>, pessoa jurídica de direito
          privado, inscrita no CNPJ/ME sob o número 29.776.278/0001-37, sediada à Rua Dr.
          Mario De Moura e Albuquerque, número 510, Cj. 152, Jardim Monte Kemel, São
          Paulo, SP, CEP 05633-010.
        </p>
        <p className="privacy__paragraph">
          Leia atentamente estas informações, elas indicam como seus dados são coletados,
          utilizados e protegidos no site da <b>DIGITAL REPUBLIC</b>. Ao fornecer Informações
          Pessoais ou navegar no site, você automaticamente concordará com as regras de
          utilização, proteção e segurança aqui estabelecidas.
        </p>
        <p className="privacy__paragraph">
          Para fins desta Política de Privacidade, "Usuários" ou "Vocês" são todos os
          indivíduos que de qualquer forma acessam o Site e/ou utilizam os serviços da{' '}
          <b>DIGITAL REPUBLIC</b>.
        </p>
        <h2 className="privacy__subtitle">1. INFORMAÇÕES GERAIS</h2>
        <p className="privacy__paragraph">
          A <b>DIGITAL REPUBLIC</b> valoriza a privacidade dos seus visitantes e Usuários, para
          tal, esta Política de Privacidade descreve de que forma a <b>DIGITAL REPUBLIC</b>{' '}
          protege a sua privacidade e seus Dados Pessoais, bem como coleta, trata e
          armazena as suas Informações Pessoais, nos termos da Lei Geral de Proteção de
          Dados e demais normativos vinculados ao tema.
        </p>
        <p className="privacy__paragraph">
          A presente Política de Privacidade se aplica a todos os Usuários que acessam ou
          utilizam o Site e/ou os Serviços da <b>DIGITAL REPUBLIC</b> sejam eles online ou
          offline, incluindo sem se limitar ao uso de nosso site{' '}
          <a href="https://www.digitalrepublic.com.br/" className="privacy__paragraph--link">https://www.digitalrepublic.com.br/</a>.
        </p>
        <p className="privacy__paragraph">
          Esta Política de Privacidade é complementar para a garantia de privacidade dos
          Usuários, não pretendendo substituir os termos e condições de qualquer contrato
          ou cláusula de privacidade que o Usuário possa ter com a <b>DIGITAL REPUBLIC</b>, nem
          quaisquer outros direitos que possam ter ao amparo de normativos, contratos e/ou
          documentos específicos.
        </p>
        <p className="privacy__paragraph">
          Ao se registrar em nossa plataforma você aceita a nossa Política de Privacidade
          e nos indica que é maior de idade e tem capacidade plena e expressa para a
          aceitação dos termos e condições desta Política para todos os fins de direito.
          Caso não concorde, ainda que em parte, com nossa Política e/ou não se enquadre
          na descrição acima, deverá desconectar-se e interromper o uso dos nossos
          Serviços.
        </p>
        <h2 className="privacy__subtitle">2. DADOS PESSOAIS E COLETA DE INFORMAÇÕES</h2>
        <p className="privacy__paragraph">
          “Dados Pessoais” são informações sobre uma pessoa natural identificada, ou
          identificável. Exemplos de Dados Pessoais são o nome completo, profissão,
          documento de identificação, endereço, e-mail, número de telefone, escolaridade,
          entre outros.{' '}
        </p>
        <p className="privacy__paragraph">
          “Tratamento” significa toda operação realizada com Dados Pessoais, como as que
          se referem à coleta, produção, recepção, classificação, utilização, acesso,
          reprodução, transmissão, distribuição, processamento, arquivamento,
          armazenamento, eliminação, avaliação ou controle da informação, modificação,
          comunicação, transferência, difusão ou extração.{' '}
        </p>
        <p className="privacy__paragraph">
          “Titular” é a pessoa natural a quem se referem os Dados Pessoais que são objeto
          do Tratamento.{' '}
        </p>
        <h2 className="privacy__subtitle">2.1. INFORMAÇÕES DOS USUÁRIOS</h2>
        <p className="privacy__paragraph">
          Na execução e no andamento de seus Serviços a <b>DIGITAL REPUBLIC</b> precisa coletar
          algumas Informações Pessoais de seus Usuários, sendo certo, porém, que as
          informações coletadas são apenas aquelas necessárias ao fornecimento de Serviços
          satisfatórios. Essa coleta tem a finalidade principalmente de prestar serviços
          ao Usuário, mas também de personalizar e facilitar suas visitas futuras e de
          ajudar a <b>DIGITAL REPUBLIC</b> a melhor direcionar suas estratégias e seu atendimento
          personalizado.
        </p>
        <p className="privacy__paragraph">
          A <b>DIGITAL REPUBLIC</b> Coleta Dados Pessoais quando o Usuário se registra para um
          evento, envia informações para candidatar-se a uma vaga, quando preenche um
          formulário de “contato” via Website, quando nos envia documentos e informações
          em reuniões (virtuais ou presenciais) ou por meio de comunicações via WhatsApp,
          ligações ou e-mails.
        </p>
        <p className="privacy__paragraph">
          Os dados pessoais podem ser coletados diretamente com o Titular dos Dados, podem
          ser obtidos com alguém relacionado ao Titular do Dado (por exemplo, a empresa
          que o Usuário faz parte) ou podem ser acessados a partir de fontes publicamente
          acessíveis. Independentemente da origem do dado, o tratamento será regido por
          esta Política de Privacidade e o Usuário poderá entrar em contato conosco
          através do e-mail <a className="privacy__paragraph--link" href="mailto:contato@digitalrepublic.com.br">contato@digitalrepublic.com.br</a> no caso de dúvidas.
        </p>
        <p className="privacy__paragraph">
          Nos limites permitidos pela legislação aplicável, a <b>DIGITAL REPUBLIC</b> poderá
          tratar os dados pessoais descritos abaixo.
        </p>
        <ol className="privacy__list" type="a">
          <li className="privacy__list--item">
            <b>Informações que você nos envia:</b> Quando se registra nos nossos Serviços e/ou
            quando entra em contato conosco diretamente por qualquer canal de comunicação
            você poderá nos enviar Informações Pessoais como nome, endereço de e-mail,
            número de telefone, endereço residencial/comercial, Informações Pessoais de
            pagamento, outras Informações Pessoais que venha a incluir nas suas
            comunicações conosco e Informações Pessoais contidas em documentos de
            identificação escaneados (como uma cédula de identidade, carteira de
            habilitação, documentos oficiais de registro de empresas, entre outros);
          </li>
          <li className="privacy__list--item">
            <b>Informações que coletamos quando você usa os Serviços:</b> Assim como acontece na
            maioria dos serviços de Internet, quando você visita nossos sites, faz
            downloads e/ou usa qualquer dos nossos Serviços, coletamos algumas informações
            automaticamente. Essas informações incluem a atividade de navegação e
            transmissão rápida de Visitantes e Usuários nos Serviços, mapas de calor e
            rolagens de sessões, Informações Pessoais não identificadoras relativas ao
            dispositivo do Visitante ou do Usuário, sistema operacional, navegador da
            internet, resolução da tela, idioma e configurações do teclado, provedor de
            serviços de internet, páginas encaminhadoras/de saída, marcações de
            data/tempo, endereços do Protocolo da Internet (IP), sites e dados de
            sequência de cliques (clickstream), dentre outras;
          </li>
          <li className="privacy__list--item">
            <b>Informações que coletamos de outras fontes:</b> Podemos receber Informações
            Pessoais de você de fontes de terceiros, como <b>i)</b> provedores de
            segurança, provedores de detecção e prevenção de fraudes, por exemplo, para
            ajudar-nos a examinar Usuários associados a fraudes; e <b>ii)</b> parceiros de
            propaganda e marketing para monitorar, gerenciar e mensurar as nossas
            campanhas de anúncios.
          </li>
        </ol>
        <h2 className="privacy__subtitle">2.2. COOKIES</h2>
        <p className="privacy__paragraph">
          O seu navegador de internet conta com a função interna de armazenar pequenos
          arquivos de texto – “cookies” – que contêm informações que permitem que um site
          reconheça a sua conta.
        </p>
        <p className="privacy__paragraph">
          O site da <b>DIGITAL REPUBLIC</b> pode usar “cookies” e outras tecnologias similares.
          Essas tecnologias nos ajudam a entender melhor o comportamento dos Usuários e
          nos informam quais partes de nosso site as pessoas visitaram, além de medir e
          contribuir para a eficácia das atividades de marketing e pesquisas na web e
          personalizar a sua experiência.
        </p>
        <p className="privacy__paragraph">
          A maioria dos navegadores permite que você controle cookies, inclusive omiti-los
          ou não e como removê-los. Você pode configurar a maioria dos navegadores para
          notificá-lo caso receba um cookie ou pode optar por bloquear cookies no seu
          navegador. Se você quiser desabilitar os cookies, verifique com o
          fabricante/navegador para saber como bloqueá-los.
        </p>
        <h2 className="privacy__subtitle">
          3. FINALIDADE DO TRATAMENTO DE DADOS PESSOAIS
        </h2>
        <p className="privacy__paragraph">
          O Tratamento dos seus Dados Pessoais pode ser realizado pela <b>DIGITAL REPUBLIC</b>{' '}
          mediante o seu consentimento, quando aplicável, em diversos meios, por obrigação
          legal, regulatória, contratual ou outras formas. A <b>DIGITAL REPUBLIC</b> pode
          solicitar que você forneça o consentimento escrito ou por qualquer meio que
          confirme tal consentimento, sempre que necessário.
        </p>
        <p className="privacy__paragraph">
          Podemos usar as suas Informações Pessoais para os seguintes propósitos:
        </p>
        <ol className="privacy__list" type="a">
          <li className="privacy__list--item">
            Prestar e operar os Serviços da <b>DIGITAL REPUBLIC</b>;
          </li>
          <li className="privacy__list--item">
            Desenvolver, personalizar e melhorar os nossos Serviços com base nas
            preferências, experiências e dificuldades comuns ou pessoais dos Usuários;
          </li>
          <li className="privacy__list--item">
            Analisar tendências e aprender sobre o comportamento do usuário no site e
            coletar informações demográficas sobre nossa base de Usuários como um todo;
          </li>
          <li className="privacy__list--item">
            Mapear, definir, criar e desenvolver estratégias comerciais e de marketing,
            bem como operar, fornecer e melhorar nossos Serviços e publicidade;
          </li>
          <li className="privacy__list--item">
            Proporcionar aos nossos Usuários atendimento ao cliente e suporte técnico
            contínuos e personalizados;
          </li>
          <li className="privacy__list--item">
            Conseguir entrar em contato com os nossos Usuários por meio de notificações e
            mensagens promocionais gerais ou personalizadas relativas aos Serviços;
          </li>
          <li className="privacy__list--item">
            Aprimorar a segurança da rede, inclusive para proteger nossos Serviços em
            benefício de todos os nossos Usuários;
          </li>
          <li className="privacy__list--item">
            Propósitos internos, como auditoria, análise de dados e pesquisa para melhorar
            os Serviços e as comunicações com clientes da <b>DIGITAL REPUBLIC</b>;
          </li>
          <li className="privacy__list--item">
            Criar dados estatísticos agregados e outras Informações Pessoais agregadas
            e/ou inferidas sobre o seu perfil e de outros Usuários que acessem o site,
            permitindo o desenvolvimento de Serviços;
          </li>
          <li className="privacy__list--item">
            Atender a obrigações legais a que a <b>DIGITAL REPUBLIC</b> esteja sujeita, cumprir
            as leis e os regulamentos aplicáveis.
          </li>
          <li className="privacy__list--item">
            Identificar e/ou oferecer-lhe conteúdo relevante sobre determinada preferência
            e/ou interesse manifestado por você à <b>DIGITAL REPUBLIC</b>, incluindo, sem
            limitação, newsletters, eventos, convites, lembretes, notas de agradecimento,
            entre outros;
          </li>
          <li className="privacy__list--item">
            Realizar pesquisa de satisfação e feedback sobre os nossos serviços e
            iniciativas.
          </li>
          <li className="privacy__list--item">
            Compor banco de dados de fornecedores e prestadores de serviço da DIGITAL
            REPUBLIC;
          </li>
          <li className="privacy__list--item">
            Formar banco de dados de candidatos a vagas de emprego, estágio e prestação de
            serviços na <b>DIGITAL REPUBLIC</b>;
          </li>
          <li className="privacy__list--item">
            Proteger, defender e administrar os interesses da <b>DIGITAL REPUBLIC</b>;
          </li>
        </ol>
        <p className="privacy__paragraph">
          Eventuais comentários feitos no site da <b>DIGITAL REPUBLIC</b> poderão ser utilizados
          para fins publicitários; isso não constitui ofensa ou violação dos seus
          direitos, nos termos do inciso X, do artigo 5º da Constituição Federal. No
          entanto, o site da <b>DIGITAL REPUBLIC</b> garante o uso de Informações Pessoais
          limitado à indicação do nome e sobrenome junto ao comentário realizado e que o
          Usuário poderá solicitar a exclusão do comentário a qualquer tempo mediante
          envio de comunicação para a <b>DIGITAL REPUBLIC</b> através do canal{' '}
          <a className="privacy__paragraph--link" href="mailto:contato@digitalrepublic.com.br">contato@digitalrepublic.com.br</a>
        </p>
        <h2 className="privacy__subtitle">
          4. COMPARTILHAMENTO E TRATAMENTO DE INFORMAÇÕES PESSOAIS
        </h2>
        <p className="privacy__paragraph">
          A <b>DIGITAL REPUBLIC</b> não comercializará os seus Dados Pessoais. Entretanto, seus
          Dados Pessoais podem ser compartilhados ou transferidos a terceiros, no Brasil
          e/ou no exterior, nas seguintes situações e nos limites exigidos e autorizados
          pela Lei:
        </p>
        <ol className="privacy__list" type="a">
          <li className="privacy__list--item">
            Com os seus clientes e parceiros quando necessário e/ou apropriado à prestação
            de Serviços e soluções relacionadas que complementam, facilitam e aprimoram os
            Serviços da <b>DIGITAL REPUBLIC</b>. Esses serviços podem ser de hospedagem e
            colocação de servidores, comunicações e redes de apresentação de conteúdo
            (CDNs), serviços de segurança cibernética e de dados, serviços de
            processamento de cobrança e pagamentos, registradores de nomes de domínio,
            serviços de detecção e prevenção de fraudes, analítica da internet, serviços
            de distribuição e monitoramento de e-mails, serviços de gravação e acesso
            remoto a sessões, serviços de mensuração de desempenho, otimização de dados e
            marketing, provedores de conteúdo e os nossos consultores jurídicos e
            financeiros;
          </li>
          <li className="privacy__list--item">
            Com as empresas e indivíduos contratados para a execução de determinadas
            atividades e serviços em nome da <b>DIGITAL REPUBLIC</b>;
          </li>
          <li className="privacy__list--item">
            Com suas empresas subsidiárias diretas e indiretas;
          </li>
          <li className="privacy__list--item">
            Com fornecedores e parceiros para consecução dos Serviços contratados com a{' '}
            <b>DIGITAL REPUBLIC</b>;
          </li>
          <li className="privacy__list--item">
            Para propósitos administrativos como: pesquisa, planejamento, desenvolvimento
            de Serviços, segurança e gerenciamento de risco;
          </li>
          <li className="privacy__list--item">
            A <b>DIGITAL REPUBLIC</b> também pode compartilhar as suas Informações Pessoais com
            terceiros para outros propósitos por sua orientação adicional ou com a sua
            aprovação expressa;
          </li>
          <li className="privacy__list--item">
            Se a <b>DIGITAL REPUBLIC</b> passar por alterações de controle, inclusive por meio de
            fusão, aquisição ou compra de substancialmente todos os seus bens, as suas
            Informações Pessoais podem ser compartilhadas com as partes envolvidas em tal
            evento.
          </li>
        </ol>
        <p className="privacy__paragraph">
          Nas hipóteses de compartilhamento de Dados Pessoais com terceiros, todos os
          sujeitos mencionados nos itens “a” a “g” deverão utilizar os Dados Pessoais
          partilhados de maneira consistente e de acordo com os propósitos para os quais
          foram coletados (ou com os quais o Usuário consentiu previamente) e de acordo
          com o que foi determinado por esta Política de Privacidade, outras declarações
          de privacidade de website ou países, e todas as leis de privacidade e proteção
          de dados aplicáveis.
        </p>
        <p className="privacy__paragraph">
          Em todas as hipóteses, a <b>DIGITAL REPUBLIC</b> se compromete a compartilhar
          estritamente os Dados Pessoais que se fizerem necessários ao cumprimento da
          respectiva finalidade.
        </p>
        <h2 className="privacy__subtitle">MOTIVOS LEGAIS PARA A DIVULGAÇÃO DE DADOS</h2>
        <p className="privacy__paragraph">
          Em certas circunstâncias, a <b>DIGITAL REPUBLIC</b> poderá divulgar Dados Pessoais, na
          medida necessária ou apropriada, para órgãos governamentais, consultores e
          outros terceiros com o objetivo de cumprir com a legislação aplicável ou com
          solicitações judiciais, como intimações, processos judiciais, mandados de busca
          ou ordens judiciais, ou ainda, se a <b>DIGITAL REPUBLIC</b> acreditar de boa-fé que tal
          ação seja necessária para:
        </p>
        <ol className="privacy__list" type="a">
          <li className="privacy__list--item">
            Cumprir com uma legislação que exija tal divulgação;
          </li>
          <li className="privacy__list--item">
            Investigar, impedir ou tomar medidas relacionadas a atividades ilegais
            suspeitas ou reais ou para cooperar com órgãos públicos ou para proteger a
            segurança nacional;
          </li>
          <li className="privacy__list--item">Execução de seus contratos;</li>
          <li className="privacy__list--item">
            Investigar e se defender contra quaisquer reivindicações ou alegações de
            terceiros;
          </li>
          <li className="privacy__list--item">
            Proteger a segurança ou a integridade dos Serviços;
          </li>
          <li className="privacy__list--item">
            Exercer ou proteger os direitos, a propriedade e a segurança da DIGITAL
            REPUBLIC e suas empresas coligadas;
          </li>
          <li className="privacy__list--item">
            Proteger os direitos e a segurança pessoal de seus funcionários, Usuários ou
            do público;
          </li>
        </ol>
        <p className="privacy__paragraph">
          A <b>DIGITAL REPUBLIC</b> poderá contestar essas demandas se julgar que as solicitações
          são excessivas, vagas ou feitas por autoridades incompetentes.
        </p>
        <h2 className="privacy__subtitle">
          6. PRIVACIDADE, SEGURANÇA E PROTEÇÃO DE INFORMAÇÕES PESSOAIS
        </h2>
        <p className="privacy__paragraph">
          A <b>DIGITAL REPUBLIC</b> e os terceiros com os quais os seus Dados Pessoais venham a
          ser eventualmente compartilhados observam os padrões de segurança necessários à
          prevenção e remediação do acesso desautorizado de Dados Pessoais, empregando os
          meios aplicáveis e padrões de segurança recomendados para protegê-los, na medida
          em que forem técnica e operacionalmente viáveis.
        </p>
        <p className="privacy__paragraph">
          A <b>DIGITAL REPUBLIC</b> e seus fornecedores utilizam procedimentos de segurança para
          proteger a confidencialidade, segurança e integridade de seus Dados Pessoais,
          prevenindo a ocorrência de eventuais danos em virtude do tratamento desses
          dados.
        </p>
        <p className="privacy__paragraph">
          Embora a <b>DIGITAL REPUBLIC</b> utilize medidas de segurança e monitore seu sistema
          para verificar vulnerabilidades e ataques para proteger seus Dados Pessoais
          contra divulgação não autorizada, mau uso ou alteração, o Usuário entende e
          concorda que não há garantias de que as informações não poderão ser acessadas,
          divulgadas, alteradas ou destruídas por violação de qualquer uma das proteções
          físicas, técnicas ou administrativas.
        </p>
        <h2 className="privacy__subtitle">7. RETENÇÃO DE DADOS</h2>
        <p className="privacy__paragraph">
          A <b>DIGITAL REPUBLIC</b> poderá armazenar os Dados Pessoais dos Usuários pelo tempo
          necessário ao cumprimento das finalidades citadas nesta política, assim como
          para o cumprimento da legislação ou regulação aplicável, conforme o caso. Para a
          determinação da forma e duração do Tratamento dos seus Dados Pessoais pela{' '}
          <b>DIGITAL REPUBLIC</b>, será considerada a natureza dos seus Dados Pessoais
          fornecidos, assim como a finalidade do Tratamento. Uma vez que a finalidade do
          Tratamento dos seus Dados Pessoais tenha sido exaurida, eles serão eliminados.
        </p>
        <p className="privacy__paragraph">
          Também ocorrerá o fim do tratamento de dados e a eliminação dos dados pessoais
          quando o Titular dos Dados estiver em seu direito de solicitar o término do
          tratamento e a exclusão de seus dados pessoais e o fizer, e/ou quando houver uma
          determinação legal neste sentido.
        </p>
        <p className="privacy__paragraph">
          A <b>DIGITAL REPUBLIC</b> poderá vir a manter seus Dados Pessoais após receber seu
          pedido de exclusão ou após o fim da finalidade caso seja necessário para
          cumprimento de obrigações legais, resolver disputas, manter a segurança, evitar
          fraudes e abuso e garantir o cumprimento de contratos.
        </p>
        <p className="privacy__paragraph">
          Para mais informações sobre onde e quanto tempo seus Dados Pessoais serão
          armazenados, bem como sobre mais informações a respeito dos seus direitos de
          exclusão e portabilidade, contate <a className="privacy__paragraph--link" href="mailto:contato@digitalrepublic.com.br">contato@digitalrepublic.com.br</a>.
        </p>
        <h2 className="privacy__subtitle">8. DIREITOS DO TITULAR DOS DADOS PESSOAIS</h2>
        <p className="privacy__paragraph">
          A <b>DIGITAL REPUBLIC</b> reconhece e concorda que toda pessoa natural tem assegurada a
          titularidade de seus Dados Pessoais e garantidos os direitos fundamentais de
          liberdade, de intimidade e de privacidade, nos termos da legislação e, por isso,
          garante que o titular dos Dados Pessoais tem direito a obter, a qualquer momento
          e mediante requisição:
        </p>
        <ol className="privacy__list" type="a">
          <li className="privacy__list--item">
            Confirmação de tratamento de dados: o Usuário pode solicitar confirmação se é
            realizado pela <b>DIGITAL REPUBLIC</b> e/ou terceiros autorizados o tratamento de
            seus dados, inclusive após o término da sua relação com a <b>DIGITAL REPUBLIC</b>;
          </li>
          <li className="privacy__list--item">
            Obtenção de informação sobre quais Dados Pessoais são armazenados e de alguma
            outra forma tratados pela <b>DIGITAL REPUBLIC</b>;
          </li>
          <li className="privacy__list--item">
            Exclusão de dados: o Usuário pode solicitar a exclusão de todos ou alguns dos
            seus Dados Pessoais (por exemplo, se eles não são mais necessários para lhe
            fornecer os Serviços);
          </li>
          <li className="privacy__list--item">
            Alteração ou correção de dados: o Usuário pode editar ou solicitar a edição
            dos seus Dados Pessoais. O Usuário também pode solicitar atualizações,
            alterações ou correções de seus dados, principalmente se eles estiverem
            incorretos, incompletos ou desatualizados;
          </li>
          <li className="privacy__list--item">
            Colocar objeções, limites ou restrições ao uso de dados: o Usuário pode
            solicitar a interrupção do uso de todos ou alguns de seus Dados Pessoais, ou
            limitar a nossa utilização de tais dados, destacando-se que a <b>DIGITAL REPUBLIC</b>
            poderá tratar os Dados Pessoais de acordo as finalidades listadas no tópico 3;
          </li>
          <li className="privacy__list--item">
            Acesso aos dados: o Usuário pode solicitar uma cópia dos seus Dados Pessoais e
            dos dados que o Usuário forneceu em um formato legível sob a forma impressa ou
            por meio eletrônico.
          </li>
        </ol>
        <p className="privacy__paragraph">
          Há circunstâncias legais que podem restringir o exercício de alguns direitos
          previstos em lei, especialmente quando o fornecimento das informações puder
          revelar algum segredo de negócio da <b>DIGITAL REPUBLIC</b>.
        </p>
        <p className="privacy__paragraph">
          O Usuário pode fazer as solicitações listadas acima entrando em contato pelo
          e-mail <a className="privacy__paragraph--link" href="mailto:contato@digitalrepublic.com.br">contato@digitalrepublic.com.br</a> e estes pedidos serão considerados de
          acordo com as leis aplicáveis.
        </p>
        <h2 className="privacy__subtitle">9. COMUNICAÇÕES DA <b>DIGITAL REPUBLIC</b></h2>
        <h2 className="privacy__subtitle">9.1. MENSAGENS PROMOCIONAIS</h2>
        <p className="privacy__paragraph">
          Podemos usar as suas Informações Pessoais para enviar-lhe conteúdo e mensagens
          promocionais por e-mail, mensagens de texto, notificações na nossa plataforma,
          chamadas de marketing e formas similares de comunicação da <b>DIGITAL REPUBLIC</b> ou
          dos nossos parceiros (atuando em representação da <b>DIGITAL REPUBLIC</b>) por tais
          meios.
        </p>
        <p className="privacy__paragraph">
          Caso não deseje receber essas mensagens ou chamadas promocionais, você pode
          notificar a <b>DIGITAL REPUBLIC</b> a qualquer momento ou seguir as instruções de
          “cancelar a inscrição” ou PARAR contidas nas comunicações promocionais que
          receber.
        </p>
        <h2 className="privacy__subtitle">9.2. MENSAGENS DE SERVIÇO</h2>
        <p className="privacy__paragraph">
          A <b>DIGITAL REPUBLIC</b> também poderá entrar em contato com você com informações
          importantes relativas aos nossos Serviços ou ao seu uso deles. É importante que
          você sempre consiga receber essas mensagens, por essa razão, você não pode
          cancelar o recebimento de tais Mensagens de Serviço a menos que deixe de ser
          Usuários da <b>DIGITAL REPUBLIC</b>.
        </p>
        <h2 className="privacy__subtitle">10. LIGAÇÕES COM SITES DE TERCEIROS</h2>
        <p className="privacy__paragraph">
          O site da <b>DIGITAL REPUBLIC</b> pode possuir ligações/links para/com outros sites, os
          quais, a nosso ver, podem conter informações/ ferramentas úteis para os nossos
          Usuários.
        </p>
        <p className="privacy__paragraph">
          Ressaltamos expressamente que a nossa política de privacidade não é aplicável a
          sites de terceiros, pelo que, caso o Usuários visite outro site a partir do site
          da <b>DIGITAL REPUBLIC</b>, deverá ler e concordar com a política de privacidade do
          referido site.
        </p>
        <p className="privacy__paragraph">
          A <b>DIGITAL REPUBLIC</b> não se responsabiliza, de nenhuma forma e sob nenhuma
          alegação, pela política de privacidade e/ou conteúdo presente nesses sites.
        </p>
        <h2 className="privacy__subtitle">
          11. ALTERAÇÕES E REVISÕES À POLÍTICA DE PRIVACIDADE
        </h2>
        <p className="privacy__paragraph">
          A <b>DIGITAL REPUBLIC</b> se reserva o direito de, a qualquer momento, modificar,
          alterar, acrescentar ou remover partes desta política, mediante publicação da
          versão atualizada nesta página.
        </p>
        <p className="privacy__paragraph">
          O uso do Site ou o fornecimento de Dados Pessoais por qualquer outro meio
          pressupõe a sua concordância com a presente Política de Privacidade.
        </p>
        <h2 className="privacy__subtitle">12. CONTATO</h2>
        <p className="privacy__paragraph">
          Em caso de dúvida com relação às disposições constantes desta Política de
          Privacidade, ou em caso de qualquer solicitação que você tenha interesse em
          fazer a respeito de seus dados, entre em contato conosco através do e-mail{' '}
          <a className="privacy__paragraph--link" href="mailto:contato@digitalrepublic.com.br">contato@digitalrepublic.com.br</a>.
        </p>
        <h2 className="privacy__subtitle">13. FORO</h2>
        <p className="privacy__paragraph">
          A presente Política de Privacidade será regida e interpretada segundo a
          legislação brasileira, sendo eleito o Foro da Comarca Central do Rio de Janeiro
          para dirimir qualquer litígio ou controvérsia envolvendo o presente documento.
        </p>
        <p className="privacy__paragraph">
          <b>Esta Política de Privacidade entra em vigor em 26/04/2023.</b>
        </p>
      </article>
    </SectionContainer>
  )
}

const Policy = () => {
  return (
    <Layout page="Política de privacidade">
      <Slogan>
        <b>Política de privacidade</b>
      </Slogan>
      <Privacy />
    </Layout>
  )
}

export const Head = () => <Seo title="Política de privacidade" />

export default Policy
